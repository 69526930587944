export const healthCategories = {
  query: `{
      healthCategories {
        id
        name
        image
        packages {
          image
          service_code
          service_name
          price {
            actual_cost
            discount_percentage
            discounted_price
          }
          tests{
          service_code
          service_name
          fasting
          type
          price {
            actual_cost
            discount_percentage
            discounted_price
          }
          visit_type
        }
        }
        tests {
          service_code
          service_name
          fasting
          type
          price {
            actual_cost
            discount_percentage
            discounted_price
          }
          visit_type
        }
      }
    }`,
};

export const labtestPackages = {
  query: `{
      packages (is_corporate:false) {
        service_code,
        service_name,
        type
        price {
          actual_cost
          discount_percentage
          discounted_price
        },
        sort,
        image,
        tests {
          service_code,
          service_name,
          fasting,
          type
          price {
            actual_cost
            discount_percentage
            discounted_price
          },
          visit_type
        },
        vendors {
          id,
          name,
          address,
          city,
          zip,
          type
        }
      }
    }`,
};
export const constructHospitalListQuery = (obj) => {
  let queryString;
  let countqueryString;
  if (obj.searchText && obj.cityId) {
    queryString = ` vendors(type : "hospital", city : "${obj.cityId}", count: ${obj.count}, page: ${obj.page}, searchText: "${obj.searchText}")`;
    countqueryString = `vendorCount (type : "hospital", city : "${obj.cityId}", searchText: "${obj.searchText}")`;
  } else if (obj.searchText) {
    queryString = ` vendors(type : "hospital", count: ${obj.count}, page: ${obj.page}, searchText: "${obj.searchText}")`;
    countqueryString = `vendorCount (type : "hospital", searchText: "${obj.searchText}")`;
  } else if (obj.cityId) {
    queryString = ` vendors(type : "hospital", city : "${obj.cityId}", count: ${obj.count}, page: ${obj.page})`;
    countqueryString = `vendorCount (type : "hospital", city : "${obj.cityId}")`;
  } else {
    queryString = ` vendors(type : "hospital", count: ${obj.count}, page: ${obj.page})`;
    countqueryString = `vendorCount (type : "hospital")`;
  }
  const queryObj = {
    query: `{
           ${queryString}{
                id,
                name,
                city,
                zip,
                address,
                rating,
                image
            doctors {
              id
              gender
              name
              languages
              specialization
              medical_registration_body
              highest_education
              slot_start_time
              slot_end_time
              zip
              city
              gst
              registration_number
              registration_expiry_date
              work_experience_years
              rating
              consultation_cost
             }
            }
            ${countqueryString}
          }`,
  };
  return queryObj;
};

export const constructHospitalDoctorsListQuery = (obj) => {
  let queryString;
  let countqueryString;
  if (obj.searchText && obj.specialization) {
    queryString = `doctors (count: ${obj.count}, page: ${obj.page}, vendorId: ${obj.hospitalId}, specialization: "${obj.specialization}", searchText: "${obj.searchText}")`;
    countqueryString = `doctorCount (vendorId: ${obj.hospitalId}, specialization: "${obj.specialization}", searchText: "${obj.searchText}"))`;
  } else if (obj.searchText) {
    queryString = `doctors (count: ${obj.count}, page: ${obj.page}, vendorId: ${obj.hospitalId}, searchText: "${obj.searchText}")`;
    countqueryString = `doctorCount (vendorId: ${obj.hospitalId}, searchText: "${obj.searchText}")`;
  } else if (obj.specialization) {
    queryString = `doctors (count: ${obj.count}, page: ${obj.page}, vendorId: ${obj.hospitalId}, specialization: "${obj.specialization}")`;
    countqueryString = `doctorCount (vendorId: ${obj.hospitalId}, specialization: "${obj.specialization}")`;
  } else {
    queryString = `doctors (count: ${obj.count}, page: ${obj.page}, vendorId: ${obj.hospitalId})`;
    countqueryString = `doctorCount (vendorId: ${obj.hospitalId})`;
  }
  const queryObj = {
    query: `{
        ${queryString} {
          id
          type
          name
          languages
          specialization
          medical_registration_body
          highest_education
          zip
          city
          gst
          registration_number
          registration_expiry_date
          work_experience_years
          rating
          virtual_consultation_cost
          chat_consultation_cost
          call_consultation_cost
          consultation_cost
          gender
          image
          slot_start_time
          slot_end_time
          available_in_90
        }
        ${countqueryString}
      }`,
  };
  return queryObj;
};

export const constructDoctorsListQuery = (obj) => {
  let queryString;
  let countqueryString;
  if (obj.searchText) {
    queryString = `doctors (count: ${obj.count}, page: ${obj.page}, virtual: true, searchText: "${obj.searchText}")`;
    countqueryString = `doctorCount (virtual: true, searchText: "${obj.searchText}")`;
  } else {
    queryString = `doctors (count: ${obj.count}, page: ${obj.page}, virtual: true)`;
    countqueryString = `doctorCount (virtual: true)`;
  }
  const queryObj = {
    query: `{
        ${queryString} {
          id
          type
          name
          languages
          specialization
          medical_registration_body
          highest_education
          zip
          city
          gst
          registration_number
          registration_expiry_date
          work_experience_years
          rating
          virtual_consultation_cost
          chat_consultation_cost
          call_consultation_cost
          consultation_cost
          gender
          image
          slot_start_time
          slot_end_time
          available_in_90
        }
        ${countqueryString}
      }`,
  };
  return queryObj;
};

export const constructDoctorHospitalListQuery = (doctorId) => {
  const queryObj = {
    query: `{
      doctorById(id: "${doctorId}"){
        id
        vendors {
          id,
          name,
          image,
          address,
          city,
          type,
          consultation_cost
        }
      }
    }`,
  };
  return queryObj;
};

export const constructHospitalDetailsQuery = (hospitalId) => {
  const queryObj = {
    query: `{
          vendorById( id: ${hospitalId}){
              id,
              name,
              city,
              zip,
              address,
              rating,
              image,
              slot_start_time
              slot_end_time,
          doctors {
            id
            name
            gender
            languages
            specialization
            medical_registration_body
            highest_education
            zip
            city
            gst
            image
            registration_number
            registration_expiry_date
            work_experience_years
            rating
            slot_start_time
            slot_end_time,
            consultation_cost
            virtual_consultation_cost
            call_consultation_cost
           }
          }   
        }`,
  };
  return queryObj;
};
export const pharmacyCategories = {
  query: `
    {
        medicineCategories {
          key
          name
          bio
          detail
          image
          medicines {
            service_code
            service_name
            type
            unit
            price {
              actual_cost
              discount_percentage
              discounted_price
            }
            image
            category_key
          }
        }
      }
        `,
};

export const getAllMedicinesQuery = {
  query: `
    {
      medicines(
        searchText: "", 
        category: "", 
        count: null, 
        page: null
      ) {
        service_code,
        search_keys,
        service_name,
        image,
        category_key,
        type,
        unit,
        company,
        description,
        price {
          actual_cost,
          discount_percentage,
          discounted_price
        }
      }
      medicineCount(
        searchText: "", 
        category: ""
      )
    }
  `,
};

export const getAllTestsQuery = {
  query: `
    {
      tests(page: null, count: null, type: "diagnostic,ctmri") {
        service_code
        service_name
        fasting
        type
        price {
          actual_cost
          discount_percentage
          discounted_price
        }
        visit_type
      }
    }
  `,
};

export const userDependentsQuery = {
  query: `{
      me {
          id,
          first_name,
          last_name,
          email,
          phone,
          gender,
          head_id,
          relation,
          dob
          age
          client{
            id,
            name,
            type,
            logo_url
            dependent_per_user
          },
      dependents {
          id,
          first_name,
          last_name,
          email,
          phone,
          gender,
          relation,
          dob,
          age,
          head_id,
          image,
        }
      }
    }`,
};
export const myAddressQuery = {
  query: `{
  addresses {
    id
    name
    address
    landmark
    city
    state
    zip
  }
    }`,
};
export const constructBookingQuery = (count) => {
  const queryObj = {
    query: `{
      bookings (role: "retail" , count : ${count}, page: 0){
        id
        collection_1_date
        collection_1_slot
        collection_2_date
        collection_2_slot
        status
        type
        created_at
        final_amount
        vendor{
          id
          name
          address
          city
          zip
          type
        }
        address{
          id
          name
          address
          city
          zip
        }
        package {
          service_code,
          service_name,
          price {
            actual_cost,
            discount_percentage
          }
        }
        test {
          service_code,
          service_name,
          type
          price {
            actual_cost,
            discount_percentage
          }
        }
        user{
          id,
          first_name,
          last_name,
          gender
        }
        doctor{
          id,
          name,
          specialization
        }
        medicines{
          count
          image
          service_code
          service_name
        }
        logs{
          id
          collection_1_date
          collection_1_slot
          status
          changed_by
          created_at
          vendor_id
        }
        attachments{
          id,
          ext,
          url
        }
      }
    }`,
  };
  return queryObj;
};

export const constructAllBookingQuery = (count, status = "") => {
  const queryObj = {
    query: `{ bookings(page: 0, count: ${count}, status: "${status}") {
    id 
    collection_1_date
    collection_1_slot
    collection_2_date
    show_virtual_call
    virtual_type
    collection_2_slot
    notes
    status
    type
    user { 
      id 
      first_name
      last_name
      age
      email
      phone
    } 
  } }`,
  };
  return queryObj;
};

export const constructAllAppointmentsQuery = (body) => {
  const query1 = {
    query:
      "query listBookings($dateSearchObject: DateSearchObjectInput){ bookings(page: 0, count: 100, dateSearchObject: $dateSearchObject) { id collection_1_date collection_1_slot collection_2_date collection_2_slot notes status type user { id first_name last_name image  gender} } }",
    variables: {
      dateSearchObject: {
        from: `${body?.from}`, //2024-05-03
        to: `${body?.to}`, //"2024-07-03"
        dateType: "scheduled",
      },
    },
  };

  // return queryObj;
  return query1;
};

export const constructAllScansQuery = {
  query: `{
    tests(page: 0, count: 30, type: "ctmri"){
      service_code,
      service_name,
      fasting,
      type
      image
      price {
        actual_cost
        discount_percentage
        discounted_price
      },
      vendors {
        id
        name
        address
        city
        zip
        type
      }
      visit_type
    },
}`,
};
export const constructAllCtMriCategoryQuery = {
  query: `{
    ctmriCategories {
      id
      name
      image
      tests {
        service_code
        service_name
        fasting
        type
        price {
          actual_cost
          discount_percentage
          discounted_price
        }
        visit_type
        vendors {
          id
          name
          address
          city
          zip
          type
        }
      }
    }
  }`,
};
export const constructgetCtMriByCategoryQuery = (categoryId) => {
  const queryObj = {
    query: `{
    ctmriCategoryById(id: ${categoryId}) {
      id
      name
      image
      tests {
        service_code
        service_name
        fasting
        type
        price {
          actual_cost
          discount_percentage
          discounted_price
        }
        visit_type
        vendors {
          id
          name
          address
          city
          zip
          type
        }
      }
    }
  }`,
  };
  return queryObj;
};

export const constructgetCtmriDetailsQuery = (body) => {
  // , city: "${city}"
  const queryObj = {
    query: `{
      test(service_code: "${body?.service_code}") {
        service_code,
        service_name,
        description,
        preperation,
        price{
          actual_cost,
          discount_percentage,
          discounted_price
        },
        fasting,
        visit_type,
        image,
        vendors{
          id,
          name,
          city,
          state,
          address,
          image,
        }
      }
    }`,
  };
  return queryObj;
};

export const getBookingIdDetails = (bookingId) => {
  const queryObj = {
    query: `
    {bookingById(id:"${bookingId}"){
      id,
      collection_1_date,
      collection_1_slot,
      collection_2_date,
      collection_2_slot,
      notes,
      status,
        user{
        id,
        first_name,
        last_name,
        phone,
        age,
        gender,
        image,
        email,
        parent{
          id,
          first_name,
          last_name,
          phone,
          email
        }
      }
      vendor{
        id,
        name,
        address
      }
        address{
        id,
        address,
        name,
        landmark,
        city,
        state,
        zip,
        latitude,
        longitude
      }
      to_address {
        id,
        address,
        name,
        city,
        state
      },
      package{
        service_code,
        service_name,
        preperation,
        description,
        image,
        visit_type
        price{
          discounted_price,
          discount_percentage,
          actual_cost
        }
      },
      test{
        service_code,
        service_name,
        preperation,
        description,
        image,
        visit_type
        price{
          discounted_price,
          discount_percentage,
          actual_cost
        }
      },
      tests{
        service_code,
        service_name,
        preperation,
        description,
        image,
        visit_type
        price{
          discounted_price,
          discount_percentage,
          actual_cost
        }
      }
      doctor{
        id,
        name,
        specialization,
          image,
        languages
      },
      type,
      final_amount,
      medicines{
        service_code,
        service_name,
        description,
        image,
        count
        price{
          discounted_price,
          discount_percentage,
          actual_cost
        }
      },
      created_at
    }}
  `,
  };
  return queryObj;
};

export const getMyPackageDetailsQuery = () => {
  const queryObj = {
    query: `
    {
      packages (is_corporate:true) {
        service_code,
        service_name,
        type
        price {
          actual_cost
          discount_percentage
          discounted_price
        },
        sort,
        image,
        tests {
          service_code,
          service_name,
          fasting,
          type
          price {
            actual_cost
            discount_percentage
            discounted_price
          },
          visit_type
        },
        vendors {
          id,
          name,
          address,
          city,
          zip,
          type
        }
      }
    
    }
  `,
  };
  return queryObj;
};

export const getEyeCategoriesQuery = (body) => {
  const queryObj = {
    query: `
    {
      tests(type : "${body.type}", city: "${body.city ?? ""}") {
            service_code,
            service_name,
            fasting,
            type
            price {
              actual_cost
              discount_percentage
              discounted_price
            }
            image,
            visit_type
        }
      }
  `,
  };
  return queryObj;
};

export const getEyeHospitalsQuery = (body) => {
  const queryObj = {
    query: `{
          vendors(type : "${body.type}" , city : "${body.city}"){
              id,
              name,
              city,
              zip,
              address
          }   
        }`,
  };
  return queryObj;
};

export const getGymCategoriesQuery = (body) => {
  const queryObj = {
    query: `
      {vendors(type:"${body?.type}",city:"${body?.city ?? ""}"){
        id,
        name,
        zip,
        address,
        image,
        city,
        type,
        state,
        tests{
          service_code,
          service_name,
          description,
          preperation,
          visit_type,
          image,
          price{
            actual_cost,
            discounted_price,
            discount_percentage
          }
        }
      }}
`,
  };
  return queryObj;
};
export const getGymVendorsQuery = (body) => {
  const queryObj = {
    query: `{
      tests(type : "${body?.type}", city: "${body?.city ?? ""}") {
            service_code,
            service_name,
            fasting,
            type
            price {
              actual_cost
              discount_percentage
              discounted_price
            }
            image,
            visit_type,
            vendors {
              id,
              name,
              address,
              city,
              zip,
              type
            }
        }
      }`,
  };
  return queryObj;
};

export const constructgetPackageDetailsQuery = (body) => {
  const queryObj = {
    query: `{
      package(service_code:"${body?.service_code}", city:"${body?.city ?? ""}"){
        service_code
        service_name
        image
        description
        preperation
        price {
          actual_cost
          discount_percentage
          service_charges
          discounted_price
        }
        sort
        tests {
          service_code
          service_name
          image
          description
          preperation
          fasting
          price {
            actual_cost
            discount_percentage
            service_charges
            discounted_price
          }
          visit_type
        }
        vendors {
          id
          name
          address
          city
          zip
          type
        }
      }
    }`,
  };
  return queryObj;
};

export const getBloodBankVendors = (city) => {
  const queryObj = {
    query: `{
      vendors(type : "blood_bank", city: "${city}"){
          id,
          name,
          city,
          zip,
          address
      }
    }`,
  };
  return queryObj;
};

export const fetchBloodTests = {
  query: `{
        bloodTests {
          id
          group
          component
          test {
            service_code
            service_name
            type
          }
        }
      }`,
};

export const fetchPatients = {
  query: `{
    patients {
      id
      first_name
      age
      last_name
      email
      secondary_email
      phone
      secondary_phone
      gender
      relation
      dob
      head_id
      patient_id
      designation
      active_status
      employee_id
      image
      address {
        id
        name
        address
        city
        state
        landmark
        zip
        latitude
        longitude
      }
    }
  }`,
};

export const getPatientDetails = (patientId) => {
  const queryObj = {
    query: `{
      patientById(id: "${patientId}") {
        id
        first_name
        last_name
        gender
        email
        phone
        age
        address {
          city
        }
        secondary_email
        secondary_phone
        dob
        designation
        image
        employee_id
      }
    }`,
  };
  return queryObj;
};

export const getDoctorByClinic = (body) => {
  const queryObj = {
    query: `{
        doctors(
            searchText: "${body?.searchText}"
            type: "all"
            count: ${parseInt(body?.count)}
            page: ${body?.page}
            activeStatus: "all"
            vendorId: ${body?.clinicId}
        ) {
            id
            name
            languages
            specialization
            medical_registration_body
            highest_education
            zip
            city
            gst
            type
            registration_number
            registration_expiry_date
            work_experience_years
            rating
            virtual_consultation_cost
            chat_consultation_cost
            call_consultation_cost
            gender
            image
            slot_start_time
            slot_end_time
            available_in_90
            state
            medical_registration_file
            pan_file
            aadhar_file
            aadhar_number
            pan_number
            status
            user {
                id
                email
                phone
                secondary_phone
                secondary_email
            }
            bank_details {
                id
                account_number
                name
                ifsc_code
            }
        }
        doctorCount(searchText: "${
          body?.searchText
        }", type: "all", activeStatus: "all")
      }`,
  };
  return queryObj;
};

export const getClientOrders = {
  query: `
    query {
          clientOrders {
          collection_1_date
          collection_1_slot
          final_amount
          id
          invoice_date
          bookings_count
          created_at,
          client{
            id,
            name
          }
        }
      }
  `,
};

export const getClients = {
  query: `
    query {
      clients(type: "all") {
        id
        name
        logo_url
        parentClient {
          id
          name
        }
           packages{
            service_code,
            service_name,
            image,
            price{
              discounted_price
            }
            tests{
              service_code,
              service_name
            }
          }
      }
    }
  `,
};

export const getClientUsers = (clientId, searchText, count, page) => {
  const queryObj = {
    query: `{ 
        clientUsers (clientId: "${clientId}", searchText : "${searchText}", count: ${count}, page: ${page}) {
        id
            phone
            first_name
            last_name
            active_status
            secondary_phone
            secondary_email
            designation
            email
            active_status
            gender
            employee_id
            dob
            age
          }
        clientUsersCount (clientId: "${clientId}", searchText : "${searchText}")
      }`,
  };
  return queryObj;
};

export const getOrderClients = {
  query: `
    query{
        clients{
          id
          name
          packages{
            service_code,
            service_name,
            image,
            price{
              discounted_price
            }
            tests{
              service_code,
              service_name
            }
          }
        }
      }
  `,
};

export const getPrescriptions = (type) => {
  const queryObj = {
    query: `
      {
         bookingAttachments(isPrescription:${type === "reports"}) {
          id,
          ext,
          url,
          booking_id
          seen,
          created_at,
          remarks,
          is_prescription,
          uploadedBy{
          id,
          first_name,
          last_name,
          email,
          phone,
          }
        }
      }   
  `,
  };
  return queryObj;
};

export const getVendorsForCity = (city, packageCode) => {
  const queryObj = {
    query: `
        query {
          vendorsForPackageAndCity(city :"${city}", packageCode:"${packageCode}"){
              id,
              name,
              image
          }
        }
      `,
  };
  return queryObj;
};
