import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Table } from "antd";
import { Image } from "antd"; // Import the Image component from Ant Design
import "./AttendanceModal.css";
import ImageUploadModel from "../../../../components/ImageUploadModel/ImageUploadModel";
import { useDispatch } from "react-redux";
import {
  addDocEmployeeAPI,
  getAllDocEmployees,
} from "../../../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";

const AttendanceRow = ({ name }: any) => {
  return (
    <div className="attendance-row">
      <div className="name">{name}</div>
      <button className="check-in">Check In</button>
      <button className="check-out">Check Out</button>
      <button className="over-time">Over Time</button>
      <div className="mark-leave">
        <input type="checkbox" id={`leave-${name}`} />
        <label htmlFor={`leave-${name}`}>Mark Leave</label>
      </div>
      <div className="savebtn">
        <button className="save">Save</button>
      </div>
    </div>
  );
};

const MarkAttendance = ({ doctorId, attendance }: any) => {
  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [showDutyRoaster, setShowDutyRoaster] = useState(false);
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [allEmployees, setAllEmployees] = useState<any[]>([]);
  const dispatch = useDispatch();

  // State to hold form input values
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");

  useEffect(() => {
    const getEmployees = async () => {
      if (!doctorId) {
        return;
      }
      const res = (await dispatch(getAllDocEmployees(doctorId))) as any;
      console.log("getEmployees : ", res?.payload);
      setAllEmployees(res?.payload);
    };
    getEmployees();
  }, [doctorId]);

  const handleAddEmployee = () => setShowAddEmployee(true);
  const handleDutyRoaster = () => setShowDutyRoaster(true);
  const handleClose = () => {
    setShowAddEmployee(false);
    setShowDutyRoaster(false);
  };

  const handleImageUploadClose = () => setShowImageUpload(false);
  const handleImageUploadSuccess = (img: any) => {
    console.log("imgUrl", img?.data?.user?.image);
    setImgUrl(img?.data?.user?.image);
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="https://placehold.co/40"
            alt="Employee"
            style={{ borderRadius: "50%", marginRight: "8px", width: "40px" }}
          />
          {text}
        </div>
      ),
    },
    {
      title: "Shift Time",
      dataIndex: "shift",
      key: "shift",
    },
    {
      title: "Role Responsibilities",
      dataIndex: "role",
      key: "role",
    },
  ];

  const data = [
    {
      key: "1",
      name: "Sree Lakshmi",
      shift: "9.00 AM To 8.00 PM",
      role: "",
    },
    {
      key: "2",
      name: "Sree Lakshmi",
      shift: "9.00 AM To 8.00 PM",
      role: "",
    },
  ];

  const handleAddEmp = async () => {
    const empBody = {
      doctor_id: parseInt(doctorId),
      name: name,
      email: email,
      phone: contact,
      // role: role,
      image: imgUrl,
    };
    console.log("empBody : ", empBody);
    const res = (await dispatch(addDocEmployeeAPI(empBody))) as any;
    if (res?.error) {
      toast.error(res?.error?.message || "Unknown error occurred");
      return;
    } else {
      toast.success("Employee Added Successfully");
      handleClose();
      return;
    }
  };

  return (
    <div className="attendance-list">
      <div className="p-2 d-flex flex-row gap-2">
        <Button onClick={handleAddEmployee}>Add Employee</Button>
        <Button onClick={handleDutyRoaster}>Duty Roaster</Button>
      </div>
      <div className="list-heading">
        <div className="Date">21-08-2024</div>
        <div className="listhead">Mark Attendance</div>
      </div>
      {allEmployees &&
        allEmployees.map((employee: any, index: number) => (
          <AttendanceRow key={employee.id} name={employee.name} />
        ))}

      {/* Add Employee Modal */}
      <Modal show={showAddEmployee} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Email ID</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Contact Number</label>
              <input
                type="text"
                className="form-control"
                value={contact}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only digits and limit input to 10 characters
                  if (/^\d*$/.test(value) && value.length <= 10) {
                    setContact(value);
                  }
                }}
              />
            </div>

            <div className="form-group">
              <Button
                variant="secondary"
                onClick={() => setShowImageUpload(true)}
              >
                Upload Image
              </Button>

              {/* Display the image if imgUrl exists */}
              {imgUrl && (
                <Image
                  src={imgUrl}
                  alt="Uploaded Image"
                  width={100}
                  height={100}
                  style={{ marginLeft: "10px", borderRadius: "8px" }}
                />
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddEmp}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Duty Roaster Modal */}
      <Modal show={showDutyRoaster} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Duty Roaster</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="duty-roaster-table">
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              rowClassName={() => "duty-roaster-row"}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Clear
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Image Upload Modal */}
      <ImageUploadModel
        show={showImageUpload}
        handleClose={handleImageUploadClose}
        handleImageUploadSuccess={handleImageUploadSuccess}
      />
    </div>
  );
};

export default MarkAttendance;
