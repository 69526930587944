import styled from "styled-components";

export const PrescriptionStyled = styled.div`
  /* Prescription Container */
  .prescription-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    margin-bottom: 16px;
  }

  .profile-section {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .user-info {
    color: #333;
  }

  .user-name {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
  }

  .user-details,
  .purpose {
    font-size: 0.9em;
    margin: 4px 0;
  }

  .user-details span,
  .purpose span {
    font-weight: bold;
  }

  .appointment-info {
    text-align: right;
    color: #555;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .doctor-name {
    font-size: 1em;
    font-weight: bold;
    margin: 0;
  }

  .date,
  .time,
  .mode {
    font-size: 0.9em;
    margin: 4px 0;
  }

  .date span,
  .time span,
  .mode span {
    font-weight: bold;
  }

  /* Issue Container */
  .issue-container,
  .medicine-container,
  .tests-container {
    padding: 16px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    margin-bottom: 16px;
  }

  .search-bar {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #e2d7d7;
    margin-bottom: 8px;
    background-color: #fff;
  }

  .icon {
    color: #b4a8f9;
    margin-right: 8px;
    cursor: pointer;
  }

  .search-bar input {
    border: none;
    outline: none;
    font-size: 0.9em;
    flex-grow: 1;
  }

  .issues-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .issue-tag {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 7px;
    border: 2px solid #f9e9e9;
    color: #5a4a4a;
    font-size: 0.9em;
  }

  .issue-tag:hover {
    background-color: #f2e4e4;
  }

  .delete-icon {
    margin-left: 8px;
    cursor: pointer;
    color: #a05858;
    &:hover {
      color: #d17a7a;
    }
  }

  /* Button styling */
  .add-button {
    background-color: #6a4df4;
    color: #fff;
    font-weight: bold;
    border-radius: 8px;
    &:hover {
      background-color: #5a3ed4;
    }
  }

  /* Custom CSS for the DatePicker */
  .custom-datepicker {
    width: 100%;
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    font-size: 14px;
    background-color: #fff;
    color: #333;
    box-sizing: border-box; /* Ensure padding is inside the width */
  }

  .custom-datepicker:focus {
    border-color: #40a9ff;
    outline: none;
  }

  .react-datepicker__header {
    background-color: #40a9ff;
    color: #fff;
    border-bottom: 1px solid #ccc;
  }

  .react-datepicker__day {
    font-size: 14px;
  }

  .react-datepicker__day--selected {
    background-color: #40a9ff;
    color: #fff;
  }

  .react-datepicker__day--disabled {
    color: #ccc;
  }

  .react-datepicker__month-text {
    color: #40a9ff;
  }

  .note-label {
    font-weight: bold;
    margin-bottom: 4px;
    color: #333;
  }

  .add-button {
    background-color: #6a4df4;
    color: #fff;
    font-weight: bold;
    border-radius: 8px;
    &:hover {
      background-color: #5a3ed4;
    }
  }

  .accordion-container {
    margin-top: 16px;

    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 8px;

      thead {
        background-color: #f0f0f0;

        th {
          padding: 8px;
          text-align: left;
          font-weight: bold;
          border: 1px solid #ccc;
        }
      }

      tbody {
        tr {
          &:nth-child(even) {
            background-color: #f9f9f9;
          }

          td {
            padding: 8px;
            border: 1px solid #ccc;
          }
        }
      }
    }
  }

  .prescription-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .profile-section,
  .appointment-info {
    padding: 8px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .button-container {
    display: flex;
    justify-content: end;
    gap: 16px;
    padding: 1rem;
  }

  .preview-button {
    padding: 10px 20px;
    border: 2px solid #28a745; /* Green border */
    background-color: transparent;
    color: #28a745;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
  }

  .preview-button:hover {
    background-color: #e6f4ea; /* Light green on hover */
  }

  .book-button {
    padding: 10px 20px;
    background-color: #8c4bff; /* Purple background */
    color: white;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
  }

  .book-button:hover {
    background-color: #7a42e0; /* Darker purple on hover */
  }

  .userImg {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
`;
