export const getTotalAmount = (cartItems) => {
  var totalAmount = 0;
  cartItems.map((itemCard, indexItem) => {
    var itemPrice = itemCard?.quantity * itemCard?.priceList[0]?.price;
    totalAmount = totalAmount + itemPrice;
  });
  return totalAmount;
};

export const getProductPrice = (product) => {
  console.log("product2", product);
  var totalAmount = "---";
  const userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  if (userData) {
    console.log("userData", userData);
    product?.priceList.map((itemp) => {
      if (
        itemp?.city == userData?.data?.city &&
        itemp?.client == userData?.data?.companyName
      ) {
        totalAmount =
          itemp?.price + "(" + itemp?.discount + "%) / " + product?.priceType;
      }
    });
  }

  return totalAmount;
};
export const handleAddtoCard = (categoryName, item) => {
  // const raphaCartL = localStorage.getItem("raphaCart");
  // const prevItems = raphaCartL ? JSON.parse(raphaCartL) : [];
  // prevItems.push({ ...item, categoryName });
  // localStorage.setItem("raphaCart", prevItems);
};

export const parseAddress = (place, addressStr) => {
  const selectedAddress = addressStr?.split(",") ?? [];
  return {
    address1: selectedAddress[0],
    address2: "",
    city:
      place?.address_components?.filter((a) =>
        a?.types?.includes("locality")
      )[0]?.long_name ||
      selectedAddress[1] ||
      "",
    state:
      place?.address_components?.filter((a) =>
        a?.types?.includes("administrative_area_level_1")
      )[0]?.long_name ||
      selectedAddress[2] ||
      "",
    country:
      place?.address_components?.filter((a) => a?.types?.includes("country"))[0]
        ?.long_name ||
      selectedAddress[3] ||
      "",
    zipcode:
      place?.address_components?.filter((a) =>
        a?.types?.includes("postal_code")
      )[0]?.long_name || "",
  };
};
export const checkIsMobile = () => {
  return window.innerWidth <= 675;
};

export function transformText(input) {
  return input
    ?.toLowerCase() // Ensure all letters are lowercase initially
    ?.split("_") // Split the string by underscores
    ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)) // Capitalize the first letter of each word
    ?.join(" "); // Join the words with a space
}
