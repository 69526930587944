import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { get, patch, put } from "../../../library/Requests/helpers";

export const getDoctorSlotsAPI = createAsyncThunk(
  "bookingScreen/getDoctorSlotsAPI",
  async (obj: any) => await patch(`${SERVER_IP}/api/v1/timeslot/doctor`, obj)
);

export const cancelBookingAPI = createAsyncThunk(
  "bookingScreen/cancelBookingAPI",
  async (cancelBkObj: any) =>
    await patch(
      `${SERVER_IP}/api/v1/booking/${cancelBkObj.bookingId}/cancel`,
      cancelBkObj.cancelObjBody
    )
);

export const raiseIssueAPI = createAsyncThunk(
  "bookingScreen/raiseIssueAPI",
  async (raiseIssueObj: any) =>
    await put(
      `${SERVER_IP}/api/v1/booking/${raiseIssueObj.bookingId}/issue`,
      raiseIssueObj.raiseIssueObjBody
    )
);

export const bookAgainAPI = createAsyncThunk(
  "bookingScreen/bookAgainAPI",
  async (bkAgainObj: any) =>
    await put(
      `${SERVER_IP}/api/v1/booking/${bkAgainObj.bookingId}/reschedule`,
      bkAgainObj.bkAgainObjBody
    )
);

export const getVirtualCallLinkAPI = createAsyncThunk(
  "bookingScreen/getVirtualCallLinkAPI",
  async (bkId: any) =>
    await get(`${SERVER_IP}/api/v1/booking/${bkId}/videocall`)
);

export const getPatientDetailsAPI = createAsyncThunk(
  "bookingScreen/getPatientDetailsAPI",
  async (bkId: any) =>
    await get(`${SERVER_IP}/api/v1/doctor/doctor-patients/${bkId}`)
);

export const getPrescriptionAPI = createAsyncThunk(
  "bookingScreen/getPrescriptionAPI",
  async (bkId: any) =>
    await get(`${SERVER_IP}/api/v1/prescription/pdfDownload?bookingId=${bkId}`)
);

export const getPatientReportsAPI = createAsyncThunk(
  "bookingScreen/getPatientReportsAPI",
  async (body: any) =>
    await get(
      `${SERVER_IP}/api/v1/doctor/patient-reports/${body?.linkableId}?firstName=${body?.searchText}&date=${body?.date}`
    )
);
