import React, { useEffect, useState } from "react";
import { ReactComponent as Calendaricon } from "../../../../assets/icons/calendar.svg";
import Calendar from "react-calendar";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Filter } from "../../../../assets/icons/filter.svg";
import { useDispatch, useSelector } from "react-redux";
import { getPatientReportsAPI } from "../../../../redux/slices/bookingScreen/bookingScreenService";
import { toast } from "react-toastify";
import { Button } from "antd";

const AppointmentOverview = () => {
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  ); // current date in YYYY-MM-DD
  const dispatch = useDispatch();
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [reports, setReports] = useState<any>([]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const getAllReports = async () => {
    const linkableId =
      user?.roles?.length > 0 ? user?.roles[0]?.linkable_id : null;
    if (!linkableId) return;

    const reportsBody = {
      linkableId: linkableId,
      searchText: searchText,
      date: selectedDate,
    };

    const res = (await dispatch(getPatientReportsAPI(reportsBody))) as any;
    if (res?.error) {
      // toast.error(res?.error?.message || "Unknown Error Occurred");
      return;
    }
    console.log("reports : ", res?.payload);
    setReports(res?.payload);
  };

  const handleDateChange = (date: any) => {
    console.log("date : ", date);
    const timezoneOffset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
    const adjustedDate = new Date(date.getTime() - timezoneOffset);
    const formattedDate = adjustedDate.toISOString().split("T")[0];
    console.log("formattedDate : ", formattedDate);
    setSelectedDate(formattedDate);
    toggleModal();
    getAllReports();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleSearch = () => {
    getAllReports(); // Call with the updated search text
  };

  // Fetch reports whenever selectedDate or searchText changes
  useEffect(() => {
    getAllReports();
  }, [selectedDate, searchText]);

  useEffect(() => {
    getAllReports(); // Initial call with today's date and empty search text
  }, []);

  return (
    <div className="bookings-sec">
      <div className="searcharfilter">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search Patient Name Serial No"
            value={searchText}
            onChange={handleSearchChange}
          />
          <Button onClick={handleSearch}>Search</Button>
        </div>
        <div className="calendar">
          <Calendaricon onClick={toggleModal} />
          <Modal
            show={showModal}
            onHide={toggleModal}
            centered
            className="custom-modal"
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <Calendar onChange={handleDateChange} />
            </Modal.Body>
          </Modal>
        </div>
        {/* <div className="filterplace">
          <button>
            Filter <Filter />
          </button>
        </div> */}
      </div>
      <table className="reports-table">
        <thead>
          <tr>
            <th>Serial No</th>
            <th>Name</th>
            <th>Age</th>
            <th>Date & Time</th>
            <th>Appointed For</th>
            <th>Report</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {reports?.map((report: any, index: any) =>
            report?.reports?.map((detail: any, idx: any) => (
              <tr key={`${report?.userId}-${idx}`}>
                <td>#{report?.userId}</td>
                <td>{report?.firstName}</td>
                <td>{report?.age || "N/A"}</td>
                <td>
                  {detail?.date} {detail?.time || ""}
                </td>
                <td>{detail?.appointedFor || "N/A"}</td>
                <td>
                  {detail?.reportUrl ? (
                    <a
                      href={detail?.reportUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      📄
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td className="actions">
                  <span role="img" aria-label="Reload">
                    🔄
                  </span>
                  <span role="img" aria-label="Download">
                    📥
                  </span>
                  <span role="img" aria-label="Call">
                    📞
                  </span>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AppointmentOverview;
